.header {
    width: 100%;
    height: 60px;
    line-height: 30px;
  }

  .container1 {
    max-width: 800px;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
    box-shadow: var(--box-shadow);
  }

  .nav__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
  }

  .menu {
    display: flex;
    align-items: center;
    column-gap: 2.7rem;
    list-style: none;
  }

  .menu__link {
    font-weight: 400;
    font-size: 0.9rem;
    cursor: pointer;
    text-decoration: none;
    color: var(--textColor);
    box-shadow: var(--box-shadow);
    padding: .2rem;
    border-radius: 5px;
  }

  .menu__link:hover {
    color: var(--textColor);
    box-shadow: var(--box-shadow-inset);
  }

  .logo h2 {
    color: var(--textColor);
    cursor: pointer;
    display: inline;
    text-decoration: none;
    box-shadow: var(--box-shadow);
    padding: .2rem;
    border-radius: 5px;
  }

  .logo h2:hover {
    color: var(--textColor);
    box-shadow: var(--box-shadow-inset);
  }

  .logo a {
    text-decoration: none;
  }

  .header__shrink {
    text-decoration: none;
    width: 100%;
    height: 70px;
    line-height: 30px;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 9999;
    background: var(--mainColor);
  }

  .mobile__menu {
    color: var(--textColor);
    font-size: 1.3rem;
    cursor: pointer;
    display: none;
  }

  .ri-menu-line {
    box-shadow: var(--box-shadow);
    padding: .2rem;
  }

  /* ========== responsive ============== */
  @media only screen and (max-width: 992px) {
    .navigation {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: var(--mainColor);
      z-index: 99999;
      display: none;
    }

    .header__shrink {
      opacity: 1;
    }
    .menu {
      flex-direction: column;
      background: var(--mainColor);
      height: 100%;
      align-items: center;
      justify-content: center;
      top: 0;
      right: 0;
      z-index: 999999;
    }

    .menu__active {
      display: block;
    }

    .mobile__menu {
      display: block;
    }
  }

  @media only screen and (max-width: 768px) {
    .header {
      width: 100%;
      height: 50px;
      line-height: 50px;
    }

    .mobile__menu {
      font-size: 1rem;
    }

    .logo h2 {
      font-size: 1.3rem;
    }
  }
