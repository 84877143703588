body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.bio {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px;
  padding-top: 0;
}

:root {
  --mainColor: #fff;
  --mainColorLight: #777;
  --secondryColor: #777;
  --textColor: black;
  --bg-color:#e6e6e6;
  --border:.2rem solid rgba(0,0,0,.2);
  --box-shadow:.4rem .4rem 1rem #ccc,
              -.4rem -.4rem 1rem #fff;
  --box-shadow-inset:.4rem .4rem 1rem #ccc inset,
                    -.4rem -.4rem 1rem #fff inset;
}