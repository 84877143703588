section img {
  max-width: 99%;
  box-shadow: var(--box-shadow);
  border: var(--border);
}

section h2, p, h1 {

  text-align: center;
}

.sec-two {
  padding-top: 20px;
}

.sec-two iframe {
  max-width: 99%;
  box-shadow: var(--box-shadow);
  border: var(--border);
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: var(--box-shadow-inset);
}

.container-bottom {
  margin-top: 80px;
  padding-top: 15px;
}

section {
  padding-bottom: 30px;
  box-shadow: var(--box-shadow);
}

section p {
  padding-bottom: 20px;
}

.sec-top {
  display: flex;
  align-items: center;
  justify-content: center;
}

.sec-social {
  box-shadow: var(--box-shadow);
}

.sec-icons {
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 25px;
  box-shadow: var(--box-shadow-inset);
}

.sec-icons a {
  padding: 10px;
  box-shadow: var(--box-shadow);
  color: var(--textColor);
}

.sec-icons a:hover {
  padding: 10px;
  box-shadow: var(--box-shadow-inset);
}

.sec-spot {
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;;
  padding: 25px;
  color: var(--textColor);
  text-decoration: none;
}

.sec-spot:hover {
  box-shadow: var(--box-shadow-inset);
}

.sec-three {
  padding-top: 20px;
}

.sec-three img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.sec-icon {
  padding-top: 20px;
}

.sec-four {
  padding-top: 20px;
}