.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title h3 {
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
    border-radius: 3px;
    width: max-content;
    margin-bottom: 0.6rem;
}
.title h3 span {
    background: var(--textColor);
    text-transform: capitalize;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}
.title h2 {
    color: var(--textColor);
    font-size: 2.5rem;
    margin-bottom: 0.8rem;
    text-align: center;
}
.title p {
    color: var(--textColor);
    font-size: 1.1rem;
    max-width: 500px;
    text-align: center;
}
.noBorder::after {
    display: none !important;
}
.children {
    margin-top: 3.5rem;
}
