.footer {
    width: 100%;
    height: 60px;
    line-height: 30px;
}

.container2 {
    max-width: 790px;
    margin: 0 auto;
    padding: 20px;
    box-shadow: var(--box-shadow);
    color: var(--textColor);
}

.footer__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-decoration: none;
}
.copyright {
    color: var(--textColor);
    margin-top: 40px;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 400;
  }