.datenschutz {
    padding: 20px;
}

.datenschutz h2 {
    padding: 20px;
}

.datenschutz p {
    padding: 20px
}