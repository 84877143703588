.client {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0rem 3rem;
    gap: 2rem;
    margin-bottom: 4rem;
    padding-top: 4rem;
}
@media screen and (max-width: 1024px) {
    .client {
        flex-direction: column-reverse;
        align-items: center;
        gap: 4rem;
   }
}
.client .info {
    width: 100%;
    height: 400px;
    user-select: none;
}
@media screen and (max-width: 1024px) {
    .client .info {
        height: auto;
   }
}
.client .info .country {
    position: relative;
    text-align: start;
    margin-bottom: 1rem;
}
.client .info .country span {
    position: relative;
    z-index: 2;
    font-size: 2rem;
    color: var(--textColor);
}
.client .info .country .svg {
    position: absolute;
    top: -4.3rem;
    left: -1.5rem;
    color: var(--textColor);
    font-size: 8rem;
}
.svg {
    opacity: .3;
}
.client .info .actions {
    display: flex;
    justify-content: center;
    gap: 1rem;
}
@media screen and (max-width: 1000px) {
    .client .info .actions {
        justify-content: center;
   }
}
@media screen and (max-width: 460px) {
    .client .info .actions {
        flex-direction: column;
   }
}
.client .info .actions a {
    display: flex;
    align-items: center;
    gap: 0.6rem;
    border-radius: 80px;
    color: var(--textColor);
    padding: 1.1rem 1.8rem;
    border: 2px solid transparent;
    font-size: 1.5rem;
    text-shadow: var(--textColor);
    letter-spacing: 0.5px;
    transition: all ease-in-out 250ms;
    text-align: center;
}
@media screen and (max-width: 1000px) {
    .client .info .actions a {
        padding: 0.85rem 1.3rem;
        font-size: 1.5rem;
   }
}
@media screen and (max-width: 550px) {
    .client .info .actions a {
        align-self: center;
        padding: 1.1rem 1.8rem;
        font-size: 1.5rem;
   }
}
.client .info .contact {
    color: var(--textColor);
}
.client .info .comment {
    font-size: 1.8rem;
    line-height: 1.8;
    color: var(--textColor);
    text-align: start;
    margin-bottom: 2rem;
    font-style: italic;
}
@media screen and (max-width: 1200px) {
    .client .info .comment {
        font-size: 1.3rem;
   }
}
.client .info .kunstler {
    font-size: 1.8rem;
    line-height: 1.8;
    color: var(--textColor);
    text-align: start;
    margin-bottom: 2rem;
    font-style: italic;
}
@media screen and (max-width: 1200px) {
    .client .info .kunstler {
        font-size: 1.3rem;
   }
}
.client .info .who {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: flex-start;
    align-items: flex-start;
}
.client .info .who .name {
    font-size: 1.5rem;
    color: var(--textColor);
}
.client .info .who .profession {
    font-size: 1rem;
    color: var(--textColor);
}
.client span {
    color: var(--textColor);
}
